<template>
  <div class="has-tooltip">
    <img
      :src="getSettingIcon('helper-icon.svg')"
      @mouseover="changeTooltipImage($event)"
      @mouseleave="changeTooltipImage($event, 'mouseleave')"
      width="18px"
      height="18px"
      alt=""
    />
    <div
      v-if="showTooltip"
      class="helper-icon tooltip"
      :class="{ 'tooltip-vi': isCheckLang === 'vi' }"
      v-html="tooltipText"
    ></div>
  </div>
</template>
<script>
import i18n from '@/lang/i18n';
export default {
  props: {
    tooltipText: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      isCheckLang: i18n.locale,
      showTooltip: false,
    }
  },
  methods: {
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.has-tooltip {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  .tooltip {
    position: absolute;
    width: max-content;
    background: $monoWhite;
    display: block;
    justify-content: left;
    border-radius: 4px;
    color: $monoBlack;
    margin: 0 auto;
    font-size: 15px;
    padding: 10px;
    height: unset;
    max-width: 400px;
    // width: 100%;
    left: 0;
    top: 20px;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
      0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
      0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  }
}
</style>